<template>
    <div class="maintenance-details">
        <header class="page-header">
            <h2>
                <router-link to="/maintenance/">Maintenance</router-link> <i class="fas fa-chevron-right"></i> Maintenance Details
            </h2>
        </header>
        <div class="content-wrapper">
            <headless-table :data="record" :columns="columns"></headless-table>
        </div>
    </div>
</template>

<script>

import MaintenanceModel from '../../models/MaintenanceModel.js'
import HeadlessTable from '../../components/tables/HeadlessTable.vue';


import axios from 'axios';

const columns = {
    'work-order-number': {
        title: "Work Order #"
    },
    'invoice-date': {
        title: "Invoice Date"
    },
    'sbs-breakdown-number': {
        title: "WO #"
    },
    'customer-po-number': {
        title: "Customer PO#"
    },
    'amount': {
        title: "Amount",
        format: "${amount}"
    }
}

export default {
    components: {
        HeadlessTable
    },
    data: function(){

        return {
            model : new MaintenanceModel(),
            record: this.$root.$data[MaintenanceModel.name][this.$route.params.id] = Object.hasOwnProperty.call(this.$root.$data[MaintenanceModel.name], this.$route.params.id) ? this.$root.$data[MaintenanceModel.name][this.$route.params.id] : new MaintenanceModel(),
            columns
        }
    },
    mounted: function(){
        console.log("mounted");
    },
    created: function(){
        console.log("created");
        if (!Object.hasOwnProperty.call(this.$root.$data[MaintenanceModel.name], this.$route.params.id)){
            this.$root.$data[MaintenanceModel.name][this.$route.params.id] = new MaintenanceModel();
        }
        axios.get('https://mockapi.starleasing.dev/maintenance').then((response) => {
            this.$root.$data[MaintenanceModel.name].populate(response.data);
        }, (e) => {
            console.log(e);
            //!! need handler for errors
        });
    }
}
</script>
<style lang="scss">
@import "../../assets/scss/variables.scss";
</style>